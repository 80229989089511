import { CollapsibleTabs, TipsList } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import StyledSectionBackground from '../StyledSectionBackground';
import { listWrap, marginContainer, wrap } from './TipsListTabsModule.module.css';

export default function TipsListTabsModule({
  blobs,
  className,
  data
}) {
  const {
    hasBackgroundColor,
    hasMarginBottom,
    tipsListTabs
  } = data;

  const dataList = tipsListTabs.map((tipsListTab) => {
    const {
      id,
      tipsList,
      title,
      trackingClass
    } = tipsListTab;

    const {
      tips,
      title : tipsListTitle
    } = tipsList;

    return {
      id,
      title,
      trackingClass,
      html : (
        <div className={listWrap}>
          {blobs}
          <TipsList title={tipsListTitle}>
            {tips.childMarkdownRemark.htmlAst}
          </TipsList>
        </div>
      )
    };
  });

  const classes = classNames(className, wrap, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <StyledSectionBackground className={classes} hasBackgroundColor={hasBackgroundColor} hasMobileWhiteBackground>
      {blobs}
      <CollapsibleTabs dataList={dataList} initialExpandedIndex={0} />
    </StyledSectionBackground>
  );
}

export const TipsListTabsDataPropsObject = {
  hasBackgroundColor : PropTypes.bool,
  hasMarginBottom    : PropTypes.bool,
  tipsListTabs       : PropTypes.arrayOf(PropTypes.shape({
    id       : PropTypes.string.isRequired,
    tipsList : PropTypes.shape({
      tips : PropTypes.shape({
        childMarkdownRemark : PropTypes.shape({
          htmlAst : PropTypes.object
        })
      }).isRequired,
      title : PropTypes.string.isRequired
    }),
    title         : PropTypes.string,
    trackingClass : PropTypes.string
  }))
};

TipsListTabsModule.propTypes = {
  blobs     : PropTypes.node,
  className : PropTypes.string,
  data      : PropTypes.shape(TipsListTabsDataPropsObject).isRequired
};

TipsListTabsModule.defaultProps = {
  blobs     : null,
  className : null
};

export const TipsListTabsModuleData = graphql`
  fragment TipsListTabsModuleData on ContentfulComponentTipsListTabs {
    hasBackgroundColor
    hasMarginBottom  
    tipsListTabs {
    id
    tipsList {
      tips {
        childMarkdownRemark {
          htmlAst
        }
      }
      title
    }
    title
    trackingClass
   }
  }
`;
