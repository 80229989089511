// extracted by mini-css-extract-plugin
export var base = "HowToOrderedListModule-module--base--arnG1";
export var rightAlignedContainer = "HowToOrderedListModule-module--rightAlignedContainer--TqHqs HowToOrderedListModule-module--rightAlignedContainerSmall--28LRb";
export var rightAlignedContainerSmall = "HowToOrderedListModule-module--rightAlignedContainerSmall--28LRb";
export var leftAlignedContainer = "HowToOrderedListModule-module--leftAlignedContainer--fdM-z";
export var blogContainer = "HowToOrderedListModule-module--blogContainer--3S0su HowToOrderedListModule-module--base--arnG1";
export var container = "HowToOrderedListModule-module--container--1T9KE HowToOrderedListModule-module--base--arnG1";
export var largeContainer = "HowToOrderedListModule-module--largeContainer--1w6ek HowToOrderedListModule-module--base--arnG1";
export var mobileSmallPadding = "HowToOrderedListModule-module--mobileSmallPadding--38WCQ";
export var fullWidthMobile = "HowToOrderedListModule-module--fullWidthMobile--2eaXy";
export var gridWithSidebar = "HowToOrderedListModule-module--gridWithSidebar--AFmV3";
export var noMaxWidth = "HowToOrderedListModule-module--noMaxWidth--3L87-";
export var marginContainer = "HowToOrderedListModule-module--marginContainer--1UhyU";
export var centeredContainer = "HowToOrderedListModule-module--centeredContainer--c4xGU";