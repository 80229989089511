import { SuccessStory } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import ModuleMarkdown from '../ModuleMarkdown';
import { container, marginContainer, wrap } from './SuccessStoryModule.module.css';

export default function SuccessStoryModule({
  blobs,
  className,
  data,
  layout
}) {
  const {
    body,
    coverImage,
    ctaCopy,
    ctaTrackingClass,
    ctaUrl,
    hasMarginBottom,
    mediaRatio,
    stat1Label,
    stat1Value,
    stat2Label,
    stat2Value,
    tagline,
    title,
    videoPoster,
    videoTitle,
    videoTrackingClass,
    videoUrl
  } = data;

  const stats = [];

  if (stat1Label) {
    stats.push({ label : stat1Label, value : stat1Value });
  }
  if (stat2Label) {
    stats.push({ label : stat2Label, value : stat2Value });
  }

  const classes = classNames(className, container, wrap, {
    [marginContainer] : hasMarginBottom
  });

  return (
    <div className={classes}>
      {blobs}
      <SuccessStory
        body={<ModuleMarkdown>{body.childMarkdownRemark.htmlAst}</ModuleMarkdown>}
        className={className}
        coverImageSrcFluid={coverImage.fluid}
        ctaCopy={ctaCopy}
        ctaTrackingClass={ctaTrackingClass}
        ctaUrl={ctaUrl}
        layout={layout}
        mediaRatio={mediaRatio}
        stats={stats}
        tagline={tagline}
        title={title}
        videoPoster={videoPoster && videoPoster.fluid.srcWebp}
        videoTitle={videoTitle}
        videoTrackingClass={videoTrackingClass}
        videoUrl={videoUrl}
      />
    </div>
  );
}

export const SuccessStoryDataPropsObject = {
  body : PropTypes.shape({
    childMarkdownRemark : PropTypes.shape({
      htmlAst : PropTypes.object
    })
  }).isRequired,
  coverImage : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number,
      sizes       : PropTypes.string,
      src         : PropTypes.string,
      srcSet      : PropTypes.string,
      srcSetWebp  : PropTypes.string,
      srcWebp     : PropTypes.string
    })
  }).isRequired,
  ctaCopy          : PropTypes.string,
  ctaTrackingClass : PropTypes.string,
  ctaUrl           : PropTypes.string,
  hasMarginBottom  : PropTypes.bool,
  mediaRatio       : PropTypes.string,
  stat1Label       : PropTypes.string,
  stat1Value       : PropTypes.string,
  stat2Label       : PropTypes.string,
  stat2Value       : PropTypes.string,
  tagline          : PropTypes.string,
  title            : PropTypes.string.isRequired,
  videoPoster      : PropTypes.shape({
    fluid : PropTypes.shape({
      srcWebp : PropTypes.string
    })
  }),
  videoTitle         : PropTypes.string.isRequired,
  videoTrackingClass : PropTypes.string,
  videoUrl           : PropTypes.string.isRequired
};

SuccessStoryModule.propTypes = {
  blobs     : PropTypes.node,
  className : PropTypes.string,
  data      : PropTypes.shape(SuccessStoryDataPropsObject).isRequired,
  layout    : PropTypes.string
};

SuccessStoryModule.defaultProps = {
  blobs     : null,
  className : null,
  layout    : 'asset-right'
};

export const SuccessStoryModuleData = graphql`
  fragment SuccessStoryModuleData on ContentfulComponentSuccessStory {
    body {
      childMarkdownRemark {
        htmlAst
      }
    }
    coverImage {
      fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    ctaCopy
    ctaTrackingClass
    ctaUrl
    hasMarginBottom
    mediaRatio
    stat1Label
    stat1Value
    stat2Label
    stat2Value
    tagline
    title
    videoPoster {
      fluid {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    videoTitle
    videoTrackingClass
    videoUrl
  }
`;
