import { HowToOrderedList } from '@animoto/components';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import {
  centeredContainer,
  marginContainer,
  rightAlignedContainer
} from './HowToOrderedListModule.module.css';

export default function HowToOrderedListModule({
  className,
  data
}) {
  const {
    ctaCopy,
    ctaTrackingClass,
    ctaTrackingClassMobile,
    ctaUrl,
    hasMarginBottom,
    listSteps,
    image,
    imageAlt,
    tagline,
    title
  } = data;

  const containerStyles = classNames(className, rightAlignedContainer, {
    [centeredContainer] : !!ctaCopy,
    [marginContainer]   : hasMarginBottom
  });

  return (
    <HowToOrderedList
      className={containerStyles}
      ctaCopy={ctaCopy}
      ctaTrackingClass={ctaTrackingClass}
      ctaTrackingClassMobile={ctaTrackingClassMobile}
      ctaUrl={ctaUrl}
      imageAlt={imageAlt}
      imageSrcFluid={image.fluid}
      tagline={tagline}
      title={title}
    >
      {listSteps.childMarkdownRemark.htmlAst}
    </HowToOrderedList>
  );
}

export const HowToOrderedListDataPropsObject = {
  ctaCopy                : PropTypes.string,
  ctaTrackingClass       : PropTypes.string,
  ctaTrackingClassMobile : PropTypes.string,
  ctaUrl                 : PropTypes.string,
  hasMarginBottom        : PropTypes.bool,
  image                  : PropTypes.shape({
    fluid : PropTypes.shape({
      aspectRatio : PropTypes.number,
      sizes       : PropTypes.string,
      src         : PropTypes.string,
      srcSet      : PropTypes.string,
      srcSetWebp  : PropTypes.string,
      srcWebp     : PropTypes.string
    })
  }),
  imageAlt  : PropTypes.string.isRequired,
  listSteps : PropTypes.shape({
    childMarkdownRemark : PropTypes.shape({
      htmlAst : PropTypes.object
    })
  }).isRequired,
  tagline : PropTypes.string,
  title   : PropTypes.string.isRequired
};

HowToOrderedListModule.propTypes = {
  className : PropTypes.string,
  data      : PropTypes.shape(HowToOrderedListDataPropsObject).isRequired
};

HowToOrderedListModule.defaultProps = {
  className : null
};

export const HowToOrderedListModuleData = graphql`
  fragment HowToOrderedListModuleData on ContentfulComponentHowToOrderedList {
    ctaCopy
    ctaTrackingClass
    ctaTrackingClassMobile
    ctaUrl
    hasMarginBottom
    listSteps {
      childMarkdownRemark {
        htmlAst
      }
    }
    image {
      fluid(maxWidth: 720) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    imageAlt
    tagline
    title
  }
`;
