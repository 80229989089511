// extracted by mini-css-extract-plugin
export var base = "SuccessStoryModule-module--base--3VT70";
export var rightAlignedContainer = "SuccessStoryModule-module--rightAlignedContainer--3NuWI";
export var rightAlignedContainerSmall = "SuccessStoryModule-module--rightAlignedContainerSmall--3Wbri";
export var leftAlignedContainer = "SuccessStoryModule-module--leftAlignedContainer--2IOIv";
export var blogContainer = "SuccessStoryModule-module--blogContainer--8W-7b SuccessStoryModule-module--base--3VT70";
export var container = "SuccessStoryModule-module--container--3u1EZ SuccessStoryModule-module--base--3VT70 SuccessStoryModule-module--container--3u1EZ SuccessStoryModule-module--base--3VT70";
export var largeContainer = "SuccessStoryModule-module--largeContainer--2vmlt SuccessStoryModule-module--base--3VT70";
export var mobileSmallPadding = "SuccessStoryModule-module--mobileSmallPadding--1oZa5";
export var fullWidthMobile = "SuccessStoryModule-module--fullWidthMobile--QIDM8";
export var gridWithSidebar = "SuccessStoryModule-module--gridWithSidebar--3RJ_u";
export var noMaxWidth = "SuccessStoryModule-module--noMaxWidth--1Df3_";
export var wrap = "SuccessStoryModule-module--wrap--8fWXF";
export var marginContainer = "SuccessStoryModule-module--marginContainer--3nC1w";